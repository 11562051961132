const getVersions = async (token) => {
    const cachedVersions = sessionStorage.getItem('versionsdata');
    const cachedTimestamp = sessionStorage.getItem('versionsTimestamp');
    const now = new Date().getTime();

    if (cachedVersions && cachedTimestamp && now - parseInt(cachedTimestamp, 10) < 3600000 ) {
        console.log('Utilisation des versions depuis le sessionStorage');
        return JSON.parse(cachedVersions);
    } else {
        return fetchVersionsFromApi(token);
    }
};

const fetchVersionsFromApi = (token) => {
    return fetch('https://backoffice.mwsc.fr:3001/get-version/', {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
    .then(response => response.text())
    .then(text => {
        const versionArray = text.split(/\s+/);
        sessionStorage.setItem('versionsdata', JSON.stringify(versionArray));
        sessionStorage.setItem('versionsTimestamp', new Date().getTime().toString());
        return versionArray;
    })
    .catch(error => {
        console.error('Il y a eu un problème avec l’opération fetch: ', error);
        throw new Error('Il y a eu un problème avec la récupération des versions.');
    });
};

export { getVersions };
