import React, { useState, useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../../authConfig';
import { getVersions } from '../Service/versionService';
import { useAuthToken } from '../Service/authService';
import { sendDeployment } from './CallBack';
import './css/Deploy.css'
import Popup from '../popup/Popup';
import versionsData from './versions.json';

const Test = () => {
    const { instance, accounts } = useMsal();
    const [token, setToken] = useState(null);
    const [versions2, setVersions] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        const request = {
            ...loginRequest,
            account: accounts[0]
        };

        instance.acquireTokenSilent(request).then(response => {
            setToken(response.accessToken);
        }).catch(error => {
            console.error(error);
        });
    }, [instance, accounts]);

    useEffect(() => {
        if (token) {
            getVersions(token)
            .then(setVersions)
            .catch(error => {
                console.error(error);
                setError('Il y a eu un problème avec la récupération des versions.');
            });
        }
    }, [token]);
    const [formData, setFormData] = useState({
        name: '',
        version: '',
        dbSettings: {
            dbIP: '',
            dbPort: '',
            dbUser: '',
            dbPassword: '',
        }
    });    
    
    const [showPopup, setShowPopup] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (['dbIP', 'dbPort', 'dbUser', 'dbPassword'].includes(name)) {
            setFormData(formData => ({
                ...formData,
                dbSettings: {
                    ...formData.dbSettings,
                    [name]: value
                }
            }));
        } else {
            setFormData(formData => ({
                ...formData,
                [name]: value
            }));
        }
    };    

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const data = await sendDeployment(token, formData);
            console.log('Success:', data);
            setShowPopup(true);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <div className="form-container">
            <form onSubmit={handleSubmit}>
                <label>
                    Nom :
                    <input type="text" name="name" value={formData.name} onChange={handleChange} />
                </label>
                <label>
                    Version :
                    <select name="version" value={formData.version} onChange={handleChange}>
                        {versions2.map((version, index) => (
                            <option key={index} value={version}>{version}</option>
                        ))}
                    </select>
                </label>
                <label>
                    IP de la Base de Données :
                    <input type="text" name="dbIP" value={formData.dbSettings.dbIP} onChange={handleChange} />
                </label>
                <label>
                    Port de la Base de Données :
                    <input type="text" name="dbPort" value={formData.dbSettings.dbPort} onChange={handleChange} />
                </label>
                <label>
                    Utilisateur DB :
                    <input type="text" name="dbUser" value={formData.dbSettings.dbUser} onChange={handleChange} />
                </label>
                <label>
                    Mot de Passe DB :
                    <input type="password" name="dbPassword" value={formData.dbSettings.dbPassword} onChange={handleChange} />
                </label>
                <button type="submit">Envoyer</button>
            </form>
            {showPopup && <div className="overlay">
                <Popup onClose={() => setShowPopup(false)} className="popup" />
            </div>}
        </div>
    );
};

export default Test;
