import React from 'react';

/*
// This file is simply used to display
//  the different menus in the navBar.
*/

const MenuItem = ({ menuName, activeMenu, handleMenuClick }) => {
  return (
    <div onClick={() => handleMenuClick(menuName)} className={`menu-item ${activeMenu === menuName ? 'active' : ''}`}>
      {menuName.charAt(0).toUpperCase() + menuName.slice(1)}
    </div>
  );
};

export default MenuItem;
