const getFileToDownload = async (token, filename) => {
    return fetchLogClientFromAPI(token, filename);
};

const fetchLogClientFromAPI = (token, filename) => {
    const url = `https://backoffice.mwsc.fr:3001/download-file?filename=${filename}`;
    return fetch(url, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
    .then(response => {
        if (!response.ok) {
            throw new Error('Erreur lors de la récupération des logs');
        }
        return response.blob();  // Utiliser blob pour les fichiers
    })
    .then(blob => {
        // Créer un lien temporaire pour télécharger le fichier
        const downloadUrl = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = downloadUrl;
        a.download = filename;  // Nom du fichier pour le téléchargement
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(downloadUrl);
    })
    .catch(error => {
        console.error('Il y a eu un problème avec l’opération fetch: ', error);
        throw new Error('Il y a eu un problème avec la récupération des logs.');
    });
};

export { getFileToDownload };
