import React, { useState, useEffect } from "react";

const GetStatus = () => {
    // Use state to hold the status colors
    const [frontStatus, setFrontStatus] = useState('#FF0000'); // Default to red
    const [backStatus, setBackStatus] = useState('#FF0000'); // Default to red

    // Define the async function inside useEffect to handle async calls
    useEffect(() => {
        async function get() {
            const response = await fetch("https://backoffice.mwsc.fr:3001/alive");
            const status = await response.json();

            // Iterate over the status.machine array
            for (let i = 0; i < status.machine.length; i++) {
                if (status.machine[i].name === 'RECETTE-BACKEND') {
                    if (status.machine[i].alive === true) {
                        setBackStatus('#00FF00');
                    } else {
                        setBackStatus('#FF0000');
                    }
                }
                if (status.machine[i].name === 'RECETTE-FRONT') {
                    if (status.machine[i].alive === true) {
                        setFrontStatus('#00FF00');
                    } else {
                        setFrontStatus('#FF0000');
                    }
                }
            }
        }

        get();
        const intervalId = setInterval(get, 60000);
        return () => clearInterval(intervalId);
    }, []); 

    return (
        <div className="sections-container-overview">
            <form action="https://grafana.mwsc.fr:3000/d/gitlab_ci_pipelines/gitlab-ci-pipelines?orgId=1&refresh=10s">
                <button className="submit-button" style={{ width: "100%" }}>
                    Pipeline status
                </button>
            </form>
            <form action="https://grafana.mwsc.fr:3000/d/gitlab_ci_jobs/gitlab-ci-jobs?orgId=1&refresh=10s">
                <button className="submit-button" style={{ width: "100%" }}>
                    Job status
                </button>
            </form>
            <div className="section-overview"> RECETTE-BACKEND
                <div style={{ background: backStatus, padding: '15px', borderRadius: "100px", margin: "10px" }}></div>
            </div>
            <div className="section-overview"> RECETTE-FRONTEND
                <div style={{ background: frontStatus, padding: '15px', borderRadius: "100px", margin: "10px" }}></div>
            </div>
        </div>
    )
}

const Status = () => {
    return (
        <div>
            <GetStatus />
        </div>
    )
}

export default Status;
