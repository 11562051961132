import React, { useState, useEffect } from 'react';
import './css/Script.css';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../../authConfig';
import Popup from '../popup/Popup';

const Script = () => {
    const { instance, accounts } = useMsal();
    const [scriptsData, setScriptsData] = useState([]);
    const [selectedScript, setSelectedScript] = useState(null);
    const [token, setToken] = useState(null);
    const [args, setArgs] = useState([]);
    const [scriptDescription, setScriptDescription] = useState("Description du script sélectionné.");
    const [error, setError] = useState(null);
    const [showPopup, setShowPopup] = useState(false);
    const [executionResult, setExecutionResult] = useState('');

    useEffect(() => {
        const request = {
            ...loginRequest,
            account: accounts[0]
        };

        instance.acquireTokenSilent(request).then(response => {
            setToken(response.accessToken);
            fetch('https://backoffice.mwsc.fr:3001/get-scripts/', {
                headers: {
                    'Authorization': `Bearer ${response.accessToken}`
                }
            })
            .then(response => response.json())
            .then(data => {
                setScriptsData(data);
            })
            .catch(error => {
                console.error('Erreur lors du chargement des scripts :', error);
                setError('Problème lors du chargement des scripts.');
            });
        }).catch(error => {
            console.error(error);
        });
    }, [instance, accounts]);

    const handleScriptChange = (event) => {
        const scriptName = event.target.value;
        const script = scriptsData.find(s => s.name === scriptName);
    
        setSelectedScript(script);
    
        if (script && script.arguments) {
            setArgs(script.arguments.map(argument => ({...argument, value: ''})));
            setScriptDescription(script.description);
        } else {
            setArgs([]);
            setScriptDescription("Description du script sélectionné.");
        }
    };
    
    

    const handleArgumentChange = (index, value) => {
        const newArgs = [...args];
        newArgs[index].value = value;
        setArgs(newArgs);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
    
        if (token && selectedScript) {
            const argsObject = args.reduce((acc, arg) => {
                acc[arg.name] = arg.value;
                return acc;
            }, {});
    
            const argsString = Object.entries(argsObject).map(([key, value]) => `${value}`).join(' ');
    
            const queryParams = new URLSearchParams({
                scriptPath: selectedScript.path,
                args: argsString
            }).toString();
    
            fetch(`https://backoffice.mwsc.fr:3001/start-script/?${queryParams}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then(response => response.json())
            .then(data => {
                setExecutionResult(data.message);
                setShowPopup(true);
            })
            .catch(error => {
                console.error('Erreur lors de l’exécution du script:', error);
                setError('Problème lors de l’exécution du script.');
                setShowPopup(true);
            });
        } else {
            setError('Script ou token non disponible.');
        }
    };
    
    const handleClosePopup = () => {
        setShowPopup(false);
    };   

    return (
        <div className="script-container">
            <h2 className="script-title">Script to Use</h2>
            <form onSubmit={handleSubmit} className="script-form">
                <div className="select-with-tooltip">
                    <select value={selectedScript ? selectedScript.name : ''} onChange={handleScriptChange} className="script-select">
                        <option value="">Select a script</option>
                        {scriptsData.map((script) => (
                            <option key={script.name} value={script.name}>{script.name}</option>
                        ))}
                    </select>
                    <span className="tooltip-icon" title={scriptDescription}>ℹ️</span>
                </div>
                <h2 className="script-parameters">Parameters</h2>
                <div className="arguments-container">
                    {args.map((arg, index) => (
                        <input
                            key={index}
                            type="text"
                            value={arg.value}
                            onChange={(e) => handleArgumentChange(index, e.target.value)}
                            placeholder={arg.name}
                            className="argument-input"
                            title={arg.description}
                        />
                    ))}
                </div>
                <div className="button-container">
                    <button className="submit-button">Start</button>
                </div>
            </form>
            {showPopup && (
                <Popup onClose={handleClosePopup}>
                    <h2>Résultat de l'exécution</h2>
                    <p>{executionResult}</p>
                </Popup>
            )}
        </div>
    );    
};

export default Script;
