import React from 'react';
import './css/LoadingSpinner.css';
import loadingImage from '../../assets/Loading.png';

// This file is simply used to display a mobile loading logo.

const LoadingSpinner = () => (
  <div className="loading-spinner-container">
    <img src={loadingImage} alt="Chargement" className="loading-spinner"/>
  </div>
);

export default LoadingSpinner;
