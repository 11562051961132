import React, { useState } from 'react';
import './css/Overview.css';
import Popup from '../popup/Popup';
import ClientDisplay from '../popup/GetClient';
import VersionDisplay from '../popup/GetVersion';
import AlertDisplay from '../popup/GetAlert';
import DownloadDisplay from '../popup/GetDownloads';
import VersionManager from './GetFirstVersion';
import NbAlertDisplay from './GetNbAlert'
import ClientCountDisplay from '../Service/CountClients';

const Overview = () => {
    const [showPopup, setShowPopup] = useState(false);
    const [popupContent, setPopupContent] = useState(null);

    const handleOpenPopup = (contentComponent) => {
        setPopupContent(contentComponent);
        setShowPopup(true);
    };

    const handleClosePopup = () => {
        setShowPopup(false);
    };

    return (
        <div>
            <div className="sections-container-overview">
                <div className="section-overview" onClick={() => handleOpenPopup(<VersionDisplay />)}>
                    <VersionManager />
                </div>
                <div className="section-overview" onClick={() => handleOpenPopup(<ClientDisplay />)}>
                    <ClientCountDisplay />
                </div>
                <div className="section-overview" onClick={() => handleOpenPopup(<AlertDisplay />)}>
                    <NbAlertDisplay />
                </div>
                <div className="section-overview" onClick={() => handleOpenPopup(<DownloadDisplay />)}>
                    <p>Téléchargement</p>
                </div>
            </div>
            {showPopup && 
                <Popup onClose={handleClosePopup}>
                    {popupContent}
                </Popup>
            }
        </div>
    );
};

export default Overview;
