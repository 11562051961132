import React, { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../../authConfig';
import { getClients } from './clientService';
import LoadingSpinner from './LoadingSpinner';

const ClientCountDisplay = () => {
    const { instance, accounts } = useMsal();
    const [token, setToken] = useState(null);
    const [clients, setClients] = useState({});
    const [error, setError] = useState(null);

    useEffect(() => {
        const request = {
            ...loginRequest,
            account: accounts[0]
        };

        instance.acquireTokenSilent(request).then(response => {
            setToken(response.accessToken);
            fetchClientCount(response.accessToken);
        }).catch(error => {
            console.error('Error during token acquisition:', error);
            setError('Problème d\'authentification');
        });
    }, [instance, accounts]);

    const fetchClientCount = (accessToken) => {
        getClients(accessToken)
            .then(data => setClients(data))
            .catch(error => {
                console.error('Error fetching clients:', error);
                setError('Problème de chargement des clients');
            });
    };

    const totalClients = () => {
        return Object.values(clients).reduce((total, entity) => {
            return total + Object.keys(entity).length;
        }, 0);
    };

    if (error) {
        return <div>Erreur : {error}</div>;
    } else if (Object.keys(clients).length === 0) {
        return <LoadingSpinner />;
    } else {
        return <div>Nombre de clients disponibles : {totalClients()}</div>;
    }
};

export default ClientCountDisplay;
