export async function sendDeployment(token, formData) {
    const { name, version, dbSettings } = formData; // Destructuration pour accéder directement aux champs

    // Création de l'objet à envoyer
    const payload = {
        name,
        version,
        dbIP: dbSettings.dbIP,
        dbPort: dbSettings.dbPort,
        dbUser: dbSettings.dbUser,
        dbPassword: dbSettings.dbPassword
    };

    try {
        const response = await fetch(`https://backoffice.mwsc.fr:3001/deploy`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(payload) // Conversion de l'objet JavaScript en chaîne JSON
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return await response.json(); // Parsing de la réponse JSON
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
}
