import React, { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import './css/GetVersion.css';
import LoadingSpinner from '../Service/LoadingSpinner';
import { loginRequest } from '../../authConfig';
import { getVersions } from '../Service/versionService';

const VersionDisplay = () => {
    const { instance, accounts } = useMsal();
    const [token, setToken] = useState(null);
    const [versions, setVersions] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        const request = {
            ...loginRequest,
            account: accounts[0]
        };

        instance.acquireTokenSilent(request).then(response => {
            setToken(response.accessToken);
        }).catch(error => {
            console.error(error);
        });
    }, [instance, accounts]);

    useEffect(() => {
        if (token) {
            getVersions(token)
            .then(setVersions)
            .catch(error => {
                console.error(error);
                setError('Il y a eu un problème avec la récupération des versions.');
            });
        }
    }, [token]);

    if (error) {
        return <div>Erreur : {error}</div>;
    } else if (versions.length === 0) {
        return <LoadingSpinner />;
    } else {
        return (
            <div className="version-list-container">
                <h3>Versions Disponibles :</h3>
                <ul className="version-list">
                    {versions.map((version, index) => (
                        <li key={index}>{version}</li>
                    ))}
                </ul>
            </div>
        );
    }
};

export default VersionDisplay;
