import React, { useState, useEffect } from 'react';
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../authConfig';
import { callMsGraph } from '../graph';
import reloadIcon from '../assets/reload-icon.svg';
import './css/Header.css';

const Header = ({ nom, prenom }) => {
    const { instance, accounts } = useMsal();
    const [graphData, setGraphData] = useState(null);

    useEffect(() => {
        if (accounts.length > 0) {
            RequestProfileData();
        }
    }, [accounts]);

    function RequestProfileData() {
        instance
            .acquireTokenSilent({
                ...loginRequest,
                account: accounts[0],
            })
            .then((response) => {
                callMsGraph(response.accessToken).then((response) => setGraphData(response));
            })
            .catch(error => {
                if (error instanceof InteractionRequiredAuthError) {
                    instance.acquireTokenRedirect(loginRequest);
                }
            });
    }

    const resetAndReload = () => {
        sessionStorage.removeItem("clientsData");
        sessionStorage.removeItem("clientsTimestamp");
        sessionStorage.removeItem("versionsdata");
        sessionStorage.removeItem("versionsTimestamp");
        sessionStorage.removeItem("alertdata");
        sessionStorage.removeItem("alerttimestamp");
        window.location.reload();
    };

    return (
      <div className="header">
          <button onClick={() => resetAndReload('specificKey')} className="refresh-button" title="Ce boutton permet de refresh le session storage">
              <img src={reloadIcon} alt="Reload" style={{ width: '25px', height: '25px' }} />
          </button>
          <div className="right-content">
              <h5 className="card-title">
                  {accounts && accounts.length > 0 ? `Welcome ${accounts[0].name}` : "Veuillez vous connecter"}
              </h5>
          </div>
      </div>
    );
};

export default Header;
