import React, { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import './css/Clients.css';
import LoadingSpinner from '../Service/LoadingSpinner';
import { loginRequest } from './../../authConfig';
import Popup from '../popup/Popup';
import { getClients } from '../Service/clientService';
import { updateClients } from '../Service/updateClientService';
import ClientVersionChangeForm from './ClientVersionChangeForm';

const ClientDisplay = () => {
    const [showPopup, setShowPopup] = useState(false);
    const [popupContent, setPopupContent] = useState('');
    const { instance, accounts } = useMsal();
    const [token, setToken] = useState(null);
    const [clients, setClients] = useState({ PROD: {}, BETA: {}, ALPHA: {} });
    const [error, setError] = useState(null);
    const [currentMenu, setCurrentMenu] = useState('PROD');

    useEffect(() => {
        const request = {
            ...loginRequest,
            account: accounts[0]
        };

        instance.acquireTokenSilent(request).then(response => {
            setToken(response.accessToken);
        }).catch(e => {
            console.error(e);
        });
    }, [instance, accounts]);

    useEffect(() => {
        if (token) {
            getClients(token)
            .then(response => {
                console.log("Data received:", response);
                setClients(response);
            })
            .catch(e => {
                console.error('Error fetching clients:', e);
                setError('Il y a eu un problème avec la récupération des clients.');
            });
        }
    }, [token]);
    

    const handleOpenPopup = (clientName, versionType, version, EXTENTION) => {
        setPopupContent(
            <ClientVersionChangeForm 
                clientName={clientName} 
                type={versionType} 
                EXTENTION={EXTENTION} 
                onSubmit={(selectedVersion) => {
                    updateClients(token, clientName, selectedVersion, versionType, EXTENTION)
                        .then(() => {
                            sessionStorage.removeItem("clientsData");
                            sessionStorage.removeItem("clientsTimestamp");
                            window.location.reload();
                        })
                        .catch((error) => {
                            console.error("Erreur lors de la mise à jour :", error);
                        });
                }}
            />
        );
        setShowPopup(true);
    };
        

    const handleClosePopup = () => {
        setShowPopup(false);
    };

    const handleMenuClick = (menu) => {
        setCurrentMenu(menu);
    };

    if (error) {
        return <div>Erreur : {error}</div>;
    } else if (Object.keys(clients[currentMenu]).length === 0) {
        return <LoadingSpinner />;
    } else {
        const menuData = clients[currentMenu] || {};

        return (
            <div className="client-list-container">
                <div className="menu-buttons">
                    <button className={`menu-button ${currentMenu === 'PROD' ? 'active' : ''}`} onClick={() => handleMenuClick('PROD')}>PROD</button>
                    <button className={`menu-button ${currentMenu === 'BETA' ? 'active' : ''}`} onClick={() => handleMenuClick('BETA')}>BETA</button>
                    <button className={`menu-button ${currentMenu === 'ALPHA' ? 'active' : ''}`} onClick={() => handleMenuClick('ALPHA')}>ALPHA</button>
                </div>
                <div className="content-container">
                    {Object.entries(menuData).map(([clientName, {version, EXTENTION}], index) => (
                        <div key={index} className="client-details">
                            <h4>{clientName} - {EXTENTION}</h4>
                            <div className="button-group">
                                <button onClick={() => handleOpenPopup(clientName, currentMenu, version, EXTENTION)}>
                                    {version}
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
                {showPopup && 
                    <Popup onClose={handleClosePopup}>
                        {popupContent}
                    </Popup>
                }
            </div>
        );        
    }
};

export default ClientDisplay;
