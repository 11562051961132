import React from 'react';
import './styles/App.css';
import { PageLayout } from './components/PageLayout';

// Ce fichier est la base du projet

export default function App() {
    return (
        <PageLayout />
    );
}
