import React, { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../../authConfig';
import { getVersions } from '../Service/versionService';
import { getLogClient } from '../Service/getLogClient';
import './css/ClientVersionChangeForm.css';

const ClientVersionChangeForm = ({ clientName, type, onSubmit }) => {
    const { instance, accounts } = useMsal();
    const [token, setToken] = useState(null);
    const [versions, setVersions] = useState([]);
    const [progress, setProgress] = useState(0);
    const [error, setError] = useState(null);
    const [showProgress, setShowProgress] = useState(false);  // État pour contrôler l'affichage de la barre de progression

    useEffect(() => {
        const request = {
            ...loginRequest,
            account: accounts[0]
        };

        instance.acquireTokenSilent(request).then(response => {
            setToken(response.accessToken);
        }).catch(error => {
            console.error(error);
        });
    }, [instance, accounts]);

    useEffect(() => {
        if (token) {
            // Récupération des versions dès que le token est disponible
            getVersions(token)
            .then(versions => {
                setVersions(versions);
            })
            .catch(error => {
                console.error(error);
                setError('Il y a eu un problème avec la récupération des versions.');
            });
        }
    
        if (token && showProgress) { // Ce bloc ne s'exécute que si showProgress est true
            const intervalId = setInterval(() => {
                getLogClient(token, clientName) // Assure-toi que getLogClient peut accepter clientName
                    .then(data => {
                        console.log(data);
                        setProgress(parseInt(data, 10)); // Convertit en entier et met à jour l'état
                    })
                    .catch(error => {
                        console.error('Failed to fetch logs:', error);
                        setError('Failed to fetch logs');
                    });
            }, 2000); // Mise à jour des logs toutes les 2 secondes
    
            return () => clearInterval(intervalId); // Nettoyage de l'intervalle
        }
    }, [token, showProgress, clientName]); // Inclut clientName et showProgress dans les dépendances

    const handleSubmit = (e) => {
        e.preventDefault();
        setShowProgress(true);  // Active l'affichage de la barre de progression
        const selectedVersion = e.target.versionSelect.value;
        onSubmit(selectedVersion);
    };

    return (
        <div className="version-change-container">
            <h2 className="version-change-title">Change la Version de {clientName} en {type}</h2>
            <form onSubmit={handleSubmit} className="version-form">
                <label htmlFor="versionSelect" className="version-label">Choisissez une nouvelle version:</label>
                <select id="versionSelect" className="version-select">
                    {versions.map((version, index) => (
                        <option key={index} value={version}>{version}</option>
                    ))}
                </select>
                <button type="submit" className="version-submit-btn">Update Version</button>
            </form>
            {error && <p className="version-error" style={{ color: 'red' }}>{error}</p>}
            {showProgress && <progress value={progress} max="100" className="log-progress"></progress>}
        </div>
    );
};

export default ClientVersionChangeForm;
