const listDownload = async (token) => {
    return fetchFilesFromApi(token);
};

const fetchFilesFromApi = (token) => {
    return fetch('https://backoffice.mwsc.fr:3001/list-downloads/', {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
    .then(response => response.json())
    .then(data => {
        if (data.success) {
            return data.files;
        } else {
            throw new Error('Erreur lors de la récupération des fichiers.');
        }
    })
    .catch(error => {
        console.error('Il y a eu un problème avec l’opération fetch: ', error);
        throw new Error('Il y a eu un problème avec la récupération des fichiers.');
    });
};

export { listDownload };
