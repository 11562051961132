const getAlert = async (token) => {
    const cachedData = sessionStorage.getItem('alertdata');
    const isDataFresh = () => {
        const cachedTimestamp = sessionStorage.getItem('alerttimestamp');
        const now = new Date().getTime();
        return cachedTimestamp && (now - parseInt(cachedTimestamp, 10)) < 3600000 ;
    };

    if (cachedData && isDataFresh()) {
        console.log('Utilisation des données alert depuis le sessionStorage');
        return JSON.parse(cachedData);
    } else {
        return fetchClientsFromApi(token);
    }
};

const fetchClientsFromApi = (token) => {
    return fetch('https://backoffice.mwsc.fr:3001/get-alert/', {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
    .then(response => response.json())
    .then(data => {
        sessionStorage.setItem('alertdata', JSON.stringify(data));
        sessionStorage.setItem('alerttimestamp', new Date().getTime().toString());
        return data;
    })
    .catch(error => {
        console.error('Il y a eu un problème avec l’opération fetch: ', error);
        throw new Error('Il y a eu un problème avec la récupération des alerts.');
    });
};

export { getAlert };
