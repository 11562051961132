import React, { useState, useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../../authConfig';
import { getAlert } from '../Service/alertService.jsx';

const Alert = () => {
    const [showPopup, setShowPopup] = useState(false);
    const { instance, accounts } = useMsal();
    const [token, setToken] = useState(null);
    const [sections, setSections] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        const request = {
            ...loginRequest,
            account: accounts[0]
        };

        instance.acquireTokenSilent(request).then(response => {
            setToken(response.accessToken);
        }).catch(e => {
            console.error(e);
        });
    }, [instance, accounts]);

    useEffect(() => {
        if (token) {
            getAlert(token)
            .then(setSections)
            .catch(e => {
                console.error('Error fetching alerts:', e);
                setError('Il y a eu un problème avec la récupération des alertes.');
            });
        }
    }, [token]);

    const Section = ({ title, importance, client, module }) => {
        const getColorForImportance = (importance) => {
            switch (importance) {
                case 1:
                    return 'green';
                case 2:
                    return 'orange';
                case 3:
                    return 'red';
                default:
                    return 'grey';
            }
        };
    
        return (
            <div className="section-alert">
                <div className="importance-indicator" style={{ backgroundColor: getColorForImportance(importance) }}></div>
                <h4>{title}</h4>
                <p>Client: {client}</p>
                <p>Module: {module}</p>
            </div>
        );
    };
    
    return (
        <div>
            <div className="sections-container-alert">
                {sections.filter(section => section.importance === 3).map(section => (
                    <Section
                        key={section.title}  // Utiliser un identifiant unique si disponible
                        title={section.title}
                        importance={section.importance}
                        client={section.client}
                        module={section.module}
                    />
                ))}
            </div>
        </div>
    );
};

export default Alert;
