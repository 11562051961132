import React, { useState } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import LogoDefault from '../assets/logo_mwsc.png';
import Logo2 from '../assets/logo_mwsc2.png';
import MenuItem from './MenuItem';
import { SignInButton } from './SignInButton';
import { SignOutButton } from './SignOutButton';
import { useIsAuthenticated } from '@azure/msal-react';
import './css/MainNavbar.css'
import {changeTheme} from "../Theme/Theme";

/*
// This file is used to display a navBar on the left-hand 
// side of the page. This navBar contains a system for selecting
// the content to be displayed in the center of the page
*/

const MenuNavbar = ({ activeMenu, handleMenuClick }) => {
  const isAuthenticated = useIsAuthenticated();
  const [clickCount, setClickCount] = useState(0);
  const [logo, setLogo] = useState(LogoDefault);


  const handleLogoClick = () => {

    const newCount = clickCount + 1;
    setClickCount(newCount);

    if (newCount === 20) {
      setLogo(Logo2);
      setClickCount(0);
    }
  };

  return (
      <Navbar bg="primary" variant="dark" className="navbarStyle">
          <a className="navbar-brand" onClick={handleLogoClick}>
              <img src={logo} height="86" alt="Logo" style={{
                  cursor: 'pointer',
                  justifyContent: 'center',
                  padding: "1.5vh",
                  marginTop: '3vh',
                  marginBottom: '3vh'
              }}/>
          </a>
          <hr className="navbar-divider"/>
          <div className="navMobile">
              <MenuItem menuName="overview" activeMenu={activeMenu} handleMenuClick={handleMenuClick}/>
              <MenuItem menuName="deploy" activeMenu={activeMenu} handleMenuClick={handleMenuClick}/>
              <MenuItem menuName="script" activeMenu={activeMenu} handleMenuClick={handleMenuClick}/>
              <MenuItem menuName="client" activeMenu={activeMenu} handleMenuClick={handleMenuClick}/>
              <MenuItem menuName="status" activeMenu={activeMenu} handleMenuClick={handleMenuClick}/>
          </div>
          <div className="collapse navbar-collapse justify-content-end">
              {isAuthenticated ? <SignOutButton/> : <SignInButton/>}
              <button id={"themeSwitch"} className={"btn btn-secondary themeButton "} onClick={function () {
                  changeTheme();
              }}> 🌗
              </button>
          </div>

      </Navbar>
  );
};

export default MenuNavbar;
