import React, { useState } from 'react';
import MenuNavbar from './MainNavbar.jsx';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import Header from './Header.jsx'

import Overview from './Overview/Overview.jsx';
import Deploy from './Deploy/Deploy.jsx';
import Script from './Script/Script.jsx';
import Alert from './Alert/Alert.jsx';
import Client from './Client/Clients.jsx';
import Status from './Status/Status';
/*
// This file defines the main page layout and manages navigation
// and conditional content rendering based on the user's authentication state.
// It utilizes `MenuNavbar` for navigation, displays the `Header`, and renders specific content
// (Overview, Deploy, Script, Alert) based on the active menu option.
// `AuthenticatedTemplate` and `UnauthenticatedTemplate` from @azure/msal-react are used
// to display content based on the user's authentication state.
*/

export const PageLayout = () => {
  const [activeMenu, setActiveMenu] = useState('overview');

  const handleMenuClick = (menu) => {
    setActiveMenu(menu);
  };

  const renderContent = () => {
    switch (activeMenu) {
      case 'overview':
        return <Overview />;
      case 'deploy':
        return <Deploy />;
      case 'script':
        return <Script />;
      case 'client':
        return <Client />;
      case 'status':
        return <Status />
      // case 'alert':
      //     return <Alert />;
      default:
        return <Overview />;
    }
  };

  return (
    <>
      <MenuNavbar activeMenu={activeMenu} handleMenuClick={handleMenuClick} />
      <Header/>
      <div className="content">
        <AuthenticatedTemplate>
          {renderContent()}
        </AuthenticatedTemplate>

        <UnauthenticatedTemplate>
          <h5 className="card-title">Please sign-in to see your profile information.</h5>
        </UnauthenticatedTemplate>
      </div>
    </>
  );
};
