import React from 'react';
import './css/Popup.css';

// This file represent the Popup component

const Popup = ({ onClose, children }) => {
    return (
        <div className="popup-backdrop">
            <div className="popup-content">
                {children}
                <button className="close-button" onClick={onClose}>Fermer</button>
            </div>
        </div>
    );
};

export default Popup;
