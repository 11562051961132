import React, { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import './css/GetClient.css';
import LoadingSpinner from '../Service/LoadingSpinner';
import { loginRequest } from '../../authConfig';
import { getClients } from '../Service/clientService';

const ClientDisplay = () => {
    const { instance, accounts } = useMsal();
    const [token, setToken] = useState(null);
    const [clients, setClients] = useState({});
    const [error, setError] = useState(null);
    const [expanded, setExpanded] = useState({});

    useEffect(() => {
        const request = {
            ...loginRequest,
            account: accounts[0]
        };

        instance.acquireTokenSilent(request).then(response => {
            setToken(response.accessToken);
        }).catch(error => {
            console.error(error);
        });
    }, [instance, accounts]);

    useEffect(() => {
        if (token) {
            getClients(token)
            .then(setClients)
            .catch(error => {
                console.error('Error fetching clients:', error);
                setError('Il y a eu un problème avec la récupération des clients.');
            });
        }
    }, [token]);
    

    const toggleExpand = (section) => {
        setExpanded(prev => ({ ...prev, [section]: !prev[section] }));
    };

    if (error) {
        return <div>Erreur : {error}</div>;
    } else if (Object.keys(clients).length === 0) {
        return <LoadingSpinner />;
    } else {
        return (
            <div className="client-list-container">
                <h3>Clients Disponibles :</h3>
                {Object.entries(clients).map(([sectionName, sectionDetails], index) => (
                    <div key={index}>
                        <h4 onClick={() => toggleExpand(sectionName)} className="section-title">
                            {sectionName} {expanded[sectionName] ? '-' : '+'}
                        </h4>
                        {expanded[sectionName] && (
                            <div className="client-list">
                                {Object.entries(sectionDetails).map(([clientName, clientDetails]) => (
                                    <div key={clientName}>
                                        <button onClick={() => toggleExpand(clientName)} className="expand-btn">
                                            {clientName} {expanded[clientName] ? '-' : '+'}
                                        </button>
                                        {expanded[clientName] && (
                                            <ul>
                                                {Object.entries(clientDetails).map(([key, value]) => (
                                                    <li key={key}>{key}: {value.replace(';\r', ';')}</li>
                                                ))}
                                            </ul>
                                        )}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                ))}
            </div>
        );
    }
};

export default ClientDisplay;
