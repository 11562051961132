const getLogClient = async (token, clientName) => {
    return fetchLogClientFromAPI(token, clientName);
};

const fetchLogClientFromAPI = (token, clientName) => {
    const url = `https://backoffice.mwsc.fr:3001/get-log/?clientName=${encodeURIComponent(clientName)}`; // Encodage pour sécurité
    return fetch(url, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
    .then(response => {
        if (!response.ok) {
            throw new Error('Erreur lors de la récupération des logs');
        }
        return response.text();
    })
    .catch(error => {
        console.error('Il y a eu un problème avec l’opération fetch: ', error);
        throw new Error('Il y a eu un problème avec la récupération des logs.');
    });
};

export { getLogClient };
