import './css/button.css';
let nightmode = false

function changeTheme () {
    const root = document.documentElement
    if (nightmode === false) {
        root.style.setProperty('--background-color', "#222831");
        root.style.setProperty('--primary-color', "#31363F");
        root.style.setProperty('--secondary-color', "#76ABAE");
        root.style.setProperty('--text-color', "#EEEEEE");
        nightmode = true
    } else {
        root.style.setProperty('--background-color', "#F1F1F1");
        root.style.setProperty('--primary-color', "#e4e4e4");
        root.style.setProperty('--secondary-color', "#615EFC");
        root.style.setProperty('--text-color', "#7E8EF1");
        nightmode = false
    }
}
export {changeTheme};