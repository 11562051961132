const updateClients = async (token, clientName, selectedVersion, selectedVersionType, EXTENTION) => {
    console.log(selectedVersion);
    const updateData = {
        clientName: clientName,
        type: selectedVersionType,
        version: selectedVersion,
        EXTENTION: EXTENTION
    };

    return fetchUpdateClientsFromApi(token, updateData);
};

const fetchUpdateClientsFromApi = (token, updateData) => {
    return fetch('https://backoffice.mwsc.fr:3001/modif-version/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(updateData)
    })
    .then(response => response.json())
    .then(data => {
        sessionStorage.setItem('updateClientsData', JSON.stringify(data));
        sessionStorage.setItem('updateClientsTimestamp', new Date().getTime().toString());
        return data;
    })
    .catch(error => {
        console.error('Il y a eu un problème avec l’opération fetch: ', error);
        throw new Error('Il y a eu un problème avec la mise à jour des clients.');
    });
};

export { updateClients };
