import React, { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import Popup from './Popup';
import { loginRequest } from '../../authConfig';
import LoadingSpinner from '../Service/LoadingSpinner';
import { listDownload } from '../Service/listDownload';
import { getFileToDownload } from '../Service/getFileToDownload';
import "./css/GetDownloads.css";

const GetDownloads = ({ onClose }) => {
    const { instance, accounts } = useMsal();
    const [token, setToken] = useState(null);
    const [files, setFiles] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const request = {
            ...loginRequest,
            account: accounts[0]
        };

        instance.acquireTokenSilent(request).then(response => {
            setToken(response.accessToken);
        }).catch(error => {
            console.error(error);
            setLoading(false); // Ajoute cette ligne pour arrêter le chargement en cas d'erreur
        });
    }, [instance, accounts]);

    useEffect(() => {
        if (token) {
            listDownload(token)
            .then(files => {
                setFiles(files);
                setLoading(false);
            })
            .catch(error => {
                console.error(error);
                setError('Il y a eu un problème avec la récupération des fichiers.');
                setLoading(false);
            });
        }
    }, [token]);

    const handleFileDownload = (file) => {
        getFileToDownload(token, file)
        .catch(error => {
            console.error('Erreur lors du téléchargement du fichier :', error);
            setError('Erreur lors du téléchargement du fichier.');
        });
    };

    if (loading) {
        return <LoadingSpinner />;
    }

    if (error) {
        return <div className="error-message">Erreur : {error}</div>;
    }

    return (
        <Popup onClose={onClose}>
            <div className="file-list-container">
                <h3>Fichiers Disponibles :</h3>
                <ul className="file-list">
                    {files.map((file, index) => (
                        <li key={index} className="file-item">
                            {file}
                            <button onClick={() => handleFileDownload(file)} className="download-button">Télécharger</button>
                        </li>
                    ))}
                </ul>
            </div>
        </Popup>
    );
};

export default GetDownloads;
