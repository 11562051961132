import React from "react";
import { useMsal } from "@azure/msal-react";
import Button from "react-bootstrap/Button";
import './css/Button.css'

/**
 * Renders a sign-out button
 */
export const SignOutButton = () => {
    const { instance } = useMsal();

    const handleLogout = () => {
        instance.logoutRedirect({
            postLogoutRedirectUri: "/",
            mainWindowRedirectUri: "/"
        }).catch(e => {
            console.log(e);
        });
    }

    return (
        <Button variant="secondary" onClick={handleLogout}>Sign Out</Button>
    )
}
