import React, { useState, useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from './../../authConfig';
import { getVersions } from '../Service/versionService';
import LoadingSpinner from '../Service/LoadingSpinner';
import VersionDisplay from '../popup/GetVersion';

const VersionManager = ({ onVersionClick }) => {
    const { instance, accounts } = useMsal();
    const [token, setToken] = useState(null);
    const [versions, setVersions] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        const request = {
            ...loginRequest,
            account: accounts[0]
        };
        instance.acquireTokenSilent(request).then(response => {
            setToken(response.accessToken);
        }).catch(err => {
            console.error('Failed to acquire token:', err);
            setError('Failed to authenticate.');
        });
    }, [instance, accounts]);

    useEffect(() => {
        if (token) {
            getVersions(token)
                .then(setVersions)
                .catch(err => {
                    console.error('Error fetching versions:', err);
                    setError('Failed to load versions.');
                });
        }
    }, [token]);

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (versions.length === 0) {
        return <LoadingSpinner />;
    }
    console.log(versions);

    return (
            <p>Dernière Version : {versions[0]}</p>
    );
};

export default VersionManager;
