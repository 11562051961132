import React, { useState, useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import './css/GetNbAlert.css'; // Assure-toi que ce fichier CSS existe et est bien configuré
import { loginRequest } from '../../authConfig';
import { getAlert } from '../Service/alertService.jsx';

const AlertCounter = () => {
    const { instance, accounts } = useMsal();
    const [token, setToken] = useState(null);
    const [alerts, setAlerts] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        const request = {
            ...loginRequest,
            account: accounts[0]
        };

        instance.acquireTokenSilent(request).then(response => {
            setToken(response.accessToken);
        }).catch(e => {
            console.error(e);
            setError('Failed to acquire token');
        });
    }, [instance, accounts]);

    useEffect(() => {
        if (token) {
            getAlert(token)
            .then(setAlerts)
            .catch(e => {
                console.error('Error fetching alerts:', e);
                setError('Problem fetching alerts.');
            });
        }
    }, [token]);

    const countAlertsByImportance = (importance) => {
        return alerts.filter(alert => alert.importance === importance).length;
    };

    const getColorForImportance = (importance) => {
        switch (importance) {
            case 1:
                return 'green';
            case 2:
                return 'orange';
            case 3:
                return 'red';
            default:
                return 'grey';
        }
    };

    return (
        <div className="getalert-counter-container">
            <h2>Nombre d'alertes :</h2>
            {error ? (
                <p>Error: {error}</p>
            ) : (
                <div className="getalert-importance-indicators">
                    {[3, 2, 1].map(importance => (
                        <div key={importance} className="getalert-importance-indicator">
                            <div className="getalert-color-particule-container">
                                <span className="getalert-color-particule" style={{
                                    backgroundColor: getColorForImportance(importance),
                                }}></span>
                                <span className="getalert-count">: {countAlertsByImportance(importance)}</span>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default AlertCounter;
